import React from 'react'
import { useEffect } from 'react';
import {Link} from 'react-router-dom';
import LokmaStudioLogo from '../assets/logo-s.svg';
const UyelikSozlesmesi = () => {
    useEffect( () => window.scrollTo(0, 0));
  return (
    <div className='MainWrapper'> 
    <Link to="/">  <img src={LokmaStudioLogo} alt="Lokma Logo" /></Link>
    <div className='wrapper'>
        
    <p class="p1 header"><strong>&Uuml;YELİK S&Ouml;ZLEŞMESİ</strong></p>
<ul class="ul1">
<li class="li2"><strong>Taraflar ve Konu</strong></li>
</ul>
<ul class="ul1">
<ul class="ul1">
<li class="li2">İşbu &Uuml;yelik S&ouml;zleşmesi, https://lokmastudio.com/ alan adlı internet sitesi (&ldquo;İnternet Sitesi&rdquo;) ve mobil uygulama (&ldquo;Uygulama&rdquo;) &uuml;zerinden faaliyetlerini y&uuml;r&uuml;ten &ldquo;Caferağa Mah. Şair Nefi Sok. No:46/5 Kadık&ouml;y / İSTANBUL&rdquo; adresinde mukim Lokma Studio Teknoloji Anonim Şirketi (&ldquo;Şirket&rdquo;) ile İnternet Sitesi veya Uygulama&rsquo;ya &uuml;ye olan ger&ccedil;ek kişi ("&Uuml;ye") arasında, İnternet Sitesi ve Uygulama&rsquo;nın kullanımına ilişkin olarak &Uuml;ye tarafından &ccedil;evrimi&ccedil;i (online) olarak onaylanmak suretiyle akdedilmiştir.</li>
<li class="li2">İnternet Sitesi ve Uygulama &uuml;zerinden bu &Uuml;yelik S&ouml;zleşmesi&rsquo;ni onaylayarak &uuml;ye olmanızdan itibaren, aşağıda yazılı olan ve &uuml;ye olmaya ilişkin &ouml;nemli bilgiler i&ccedil;eren hususları b&uuml;t&uuml;n&uuml;yle anlamış ve tamamını ayrılmaz bir b&uuml;t&uuml;n olarak kabul etmiş sayılırsınız. &Uuml;ye olmadan &ouml;nce l&uuml;tfen bu &Uuml;yelik S&ouml;zleşmesi&rsquo;ni dikkatle okuyunuz ve &Uuml;yelik S&ouml;zleşmesi&rsquo;nin i&ccedil;erdiği t&uuml;m maddeleri ve h&uuml;k&uuml;mleri onaylamıyorsanız &uuml;yelik s&uuml;recini tamamlamayınız. Yeni &uuml;yelik oluşturma esnasında, bu metni okuduğunuzu ifade eden bir b&ouml;l&uuml;mle karşılaşacaksınız, bu kısmı işaretlemeniz aşağıdaki maddelerin tamamını kabul ettiğiniz anlamına gelecektir.</li>
<li class="li2">&Uuml;yelik S&ouml;zleşmesi&rsquo;nde yer almayan her t&uuml;rl&uuml; bilgi ve tanım i&ccedil;in işbu &Uuml;yelik S&ouml;zleşmesi&rsquo;nin İnternet Sitesi ve Uygulama&rsquo;da bulunan diğer belge ve a&ccedil;ıklamalarla birlikte ayrılmaz par&ccedil;alarından biri olan Kullanım Koşulları ge&ccedil;erlidir.</li>
</ul>
</ul>
<ul class="ul1">
<li class="li2"><strong>Koşullar</strong></li>
<ul class="ul1">
<li class="li2">Bu &Uuml;yelik S&ouml;zleşmesi, İnternet Sitesi ve Uygulama&rsquo;yı &ldquo;&uuml;ye&rdquo; olarak kullanan ger&ccedil;ek kişi ile Şirket arasında &Uuml;ye tarafından &ccedil;evrimi&ccedil;i (online) olarak onaylanarak kurulmuştur. Şirket, &Uuml;yelik S&ouml;zleşmesi&rsquo;nde belirtilen koşulları her zaman ve herhangi bir ihbarda veya bildirimde bulunmadan değiştirebilir. &Uuml;yelik S&ouml;zleşmesi, &Uuml;ye&rsquo;nin tek taraflı beyanı ile değiştirilemez.</li>
<li class="li2">Bu &Uuml;yelik S&ouml;zleşmesi&rsquo;ni kabul etmekle; (i) &Uuml;yeler 18 yaşından b&uuml;y&uuml;k ve s&ouml;zleşme yapmak i&ccedil;in gereken fiil ehliyetine sahip olduğunuzu, (ii) herhangi bir sebeple &uuml;yelikten ge&ccedil;ici veya s&uuml;rekli olarak uzaklaştırılmadıklarını kabul ve beyan etmektesiniz.<span class="Apple-converted-space">&nbsp;</span></li>
<li class="li2">&Uuml;ye, İnternet Sitesi ve Uygulama&rsquo;ya &uuml;ye olurken verdiği kişisel verileri ile sair bilgilerin doğru olduğunu, <span class="s1">Şirket&rsquo;in</span> bu bilgilerin ger&ccedil;eğe aykırı olması halinde bu nedenle uğrayacağı t&uuml;m zararları derhal ve nakden tazmin edeceğini kabul ve taahh&uuml;t eder.</li>
<li class="li2">&Uuml;ye, İnternet Sitesi ve Uygulama&rsquo;nın kullanımına ilişkin olan kullanıcı adı ve şifre bilgilerini korumakla y&uuml;k&uuml;ml&uuml;d&uuml;r. &Uuml;ye, kendisine ait bu bilgileri &uuml;&ccedil;&uuml;nc&uuml; şahıslara a&ccedil;ıklayamaz ve &uuml;&ccedil;&uuml;nc&uuml; şahısların kullanımına sunamaz. &Uuml;ye, kendisine ait bu bilgilerin izinsiz kullanıldığını &ouml;ğrenirse durumu derhal <span class="s1">Şirket&rsquo;e</span> bildirmeyi taahh&uuml;t eder. <span class="s1">Şirket</span>, izinsiz kullanımı engellemek amacıyla &Uuml;ye&rsquo;nin erişimini engelleme ve/veya &uuml;yeliğini sona erdirme hakkına sahiptir.</li>
<li class="li2">&Uuml;ye; İnternet Sitesi ve Uygulama&rsquo;nın kullanımında y&uuml;r&uuml;rl&uuml;kteki mevzuat h&uuml;k&uuml;mlerin uygun davranacağını, İnternet Sitesi ve Uygulama&rsquo;yı kamu d&uuml;zenini bozucu, genel ahlaka aykırı, başkalarını rahatsız ve taciz edici şekilde, yasalara aykırı bir ama&ccedil; i&ccedil;in, başkalarının fikri ve telif haklarına tecav&uuml;z teşkil edecek şekilde kullanmayacağını, başkalarının hizmetleri kullanmasını &ouml;nleyici veya zorlaştırıcı faaliyet (spam, vir&uuml;s, truva atı,vb.) ve işlemlerde bulunmayacağını kabul ve taahh&uuml;t eder.<span class="Apple-converted-space">&nbsp;</span></li>
<li class="li2">&Uuml;yelik S&ouml;zleşmesi&rsquo;ni ihlal eden &Uuml;ye işbu ihlal nedeniyle cezai ve hukuki olarak şahsen sorumlu olup <span class="s1">Şirket&rsquo;i</span> bu ihlallerin hukuki ve cezai sonu&ccedil;larından ari tutacaktır.<span class="Apple-converted-space">&nbsp;</span></li>
<li class="li2">&Uuml;ye kabul&uuml;nde takdir yetkisi yalnızca ve sadece <span class="s1">Şirket&rsquo;e </span>aittir. &Uuml;yelik başvurusu <span class="s1">Şirket</span> tarafından değerlendirilecek olup <span class="s1">Şirket</span> herhangi bir gerek&ccedil;e g&ouml;stermeye gerek duymadan &uuml;yelik taleplerini geri &ccedil;evirme hakkına sahiptir. <span class="s1">Şirket</span> kullanılmayan &uuml;yelikleri ge&ccedil;ersiz kılma hakkını haizdir. &Uuml;yelik işlemleri tamamlandıktan sonra da <span class="s1">Şirket,</span> &uuml;yeliği ge&ccedil;ici olarak veya s&uuml;rekli olarak durdurmak, iptal etmek, ayrıca hi&ccedil;bir neden g&ouml;stermeksizin hizmet vermeyi kesme hakkını haizdir.</li>
<li class="li2">&Uuml;yeler, Şirket&rsquo;in ilgili b&ouml;l&uuml;mlerine girilen hi&ccedil;bir bilgiyi ve/veya i&ccedil;eriği kontrol etmekle y&uuml;k&uuml;ml&uuml; olmadığını ve kendisine verilen bilgilerin doğruluğunu hi&ccedil;bir koşul altında taahh&uuml;t etmediğini peşinen kabul, beyan ve taahh&uuml;t eder.<span class="Apple-converted-space">&nbsp;</span></li>
<li class="li2">&Uuml;yeler, y&uuml;r&uuml;rl&uuml;kte olan mevzuatın y&uuml;klediği sorumlulukları bilmedikleri veya bilebilecek durumda olmadıklarını ileri s&uuml;remez. Şirket, mevzuata aykırı olduğunu veya herhangi bir konuda karışıklığa sebep olacağını tespit ettiği i&ccedil;erikleri yayımlamaktan imtina edebilir. Şirket&rsquo;in, yayımlanan veya yayımlanmamasına rağmen bilişim sistemlerine &Uuml;ye tarafından herhangi bir şekilde girilen herhangi bir bilgi veya veri sebebi ile bir hukuki veya cezai sorumlulukla karşılaşması halinde &Uuml;ye&rsquo;ye r&uuml;cu hakkı saklıdır.</li>
<li class="li2">&Uuml;yeler, oluşturdukları profil sayfalarından bizzat sorumludur. Şirket&rsquo;in profil sayfası da dahil olmak &uuml;zere İnternet Sitesi ve Uygulama&rsquo;nın her t&uuml;rl&uuml; b&ouml;l&uuml;m&uuml;n&uuml; dilediği zaman, ayrıca herhangi bir bildirimde bulunmadan değiştirme hakkı her zaman saklıdır.</li>
<li class="li2">Şirket, İnternet Sitesi ve Uygulama&rsquo;nın genel g&ouml;r&uuml;n&uuml;m ve dizaynı ile İnternet Sitesi ve Uygulama&rsquo;daki t&uuml;m bilgi, resim, Lokma Studio markası ve diğer markalar, alan adı, logo, ikon, demonstratif, yazılı, elektronik, grafik veya makinede okunabilir şekilde sunulan teknik veriler, bilgisayar yazılımları, uygulanan satış sistemi, iş metodu ve iş modeli de dahil t&uuml;m materyallerin ve de i&ccedil;eriklerin ve bunlara ilişkin fikri ve sınai m&uuml;lkiyet haklarının sahibi veya lisans sahibidir ve t&uuml;m bu haklar yasal olarak koruma altındadır. İnternet Sitesi ve Uygulama&rsquo;da bulunan hi&ccedil;bir materyal ve i&ccedil;erik; &ouml;nceden izin alınmadan ve kaynak g&ouml;sterilmeden, kod ve yazılım da dahil olmak &uuml;zere, değiştirilemez, kopyalanamaz, &ccedil;oğaltılamaz, başka bir lisana &ccedil;evrilemez, yeniden yayımlanamaz, başka bir bilgisayara y&uuml;klenemez, postalanamaz, iletilemez, sunulamaz ya da dağıtılamaz.</li>
<li class="li3">Şirket<span class="s1"> ile paylaşılan kişisel veriler, 6698 sayılı Kişisel Verilerin Korunması Kanunu (&ldquo;KVKK&rdquo;) kapsamında işlenmektedir. Kişisel verilere ilişkin daha fazla bilgi edinmek i&ccedil;in l&uuml;tfen Kişisel Verilerin İşlenmesine İlişkin Aydınlatma Metni [link]&rsquo;ni inceleyiniz. </span>Şirket, &Uuml;ye&rsquo;nin kişisel bilgilerini yasal bir zorunluluk olarak istendiğinde veya (i) yasal gereklere uygun hareket etmek veya Şirket&rsquo;e tebliğ edilen yasal işlemlere uymak; (ii) Şirket&rsquo;in haklarını korumak i&ccedil;in gerekli olduğuna kanaat getirdiği hallerde a&ccedil;ıklayabilir.</li>
<li class="li2">İnternet Sitesi ve Uygulama&rsquo;nın vir&uuml;s ve benzeri ama&ccedil;lı yazılımlardan arındırılmış olması i&ccedil;in mevcut imkanlar dahilinde tedbir alınmıştır. Bununla birlikte, nihai g&uuml;venliğin sağlanması i&ccedil;in &Uuml;ye&rsquo;nin, kendi vir&uuml;s koruma sistemini tedarik etmesi ve gerekli korunmayı sağlaması gerekmektedir.</li>
<li class="li2">Taraflar, <span class="s1">Şirket&rsquo;e</span> ait t&uuml;m bilgisayar kayıtlarının tek ve ger&ccedil;ek m&uuml;nhasır delil olarak, 6100 sayılı Hukuk Muhakemeleri Kanunu&rsquo;nun 193. maddesi uyarınca esas alınacağını ve işbu maddenin bir delil s&ouml;zleşmesi teşkil ettiği hususunu kabul ve beyan ederler.</li>
<li class="li2">İşbu &Uuml;yelik S&ouml;zleşmesi&rsquo;nden doğan ihtilaflar bakımından İstanbul Anadolu Mahkemeleri ve İcra Daireleri yetkilidir. <span class="s1"><span class="Apple-converted-space">&nbsp;</span></span></li>
<li class="li3"><span class="s1">İnternet Sitesi ve Uygulama&rsquo;da yayınlanan </span>Kullanım Koşulları, Aydınlatma Metni ve benzeri her t&uuml;rl&uuml; dok&uuml;man ve uyarı metni işbu &Uuml;yelik S&ouml;zleşmesi&rsquo;nin eki ve ayrılmaz bir par&ccedil;ası niteliğindedir.<span class="Apple-converted-space">&nbsp;</span></li>
</ul>
</ul>
    </div>
</div>
  )
}

export default UyelikSozlesmesi