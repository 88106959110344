import React from 'react'
import { useEffect } from 'react';
import LokmaStudioLogo from '../assets/logo-s.svg';
import {Link} from 'react-router-dom'
function KullanimKosullari() {  useEffect( () => window.scrollTo(0, 0));
  return (
    <div className='MainWrapper'> 

  <Link to="/">  <img src={LokmaStudioLogo} alt="Lokma Logo" /></Link>
  <div className='wrapper'>
        <p class="p1 header"><strong>KULLANIM KOŞULLARI</strong></p>
<p class="p2"><strong>1.&nbsp;TARAFLAR VE KONU</strong></p>
<p class="p2"><strong>1.1.&nbsp;</strong>İşbu Kullanım Koşulları, Ziyaret&ccedil;i veya &Uuml;ye olarak Uygulama&rsquo;ya erişim sağlayan kişilerin tamamı i&ccedil;in ge&ccedil;erlidir. Bu nedenle l&uuml;tfen aşağıda yazılı koşulları dikkatlice okuyunuz. Bu koşulları kabul etmiyorsanız İnternet Sitesi&rsquo;ni ve/veya Uygulama&rsquo;yı ziyaret etmeyiniz ve/veya kullanmayınız.</p>
<p class="p2"><strong>1.2.&nbsp;</strong>Uygulama&rsquo;nın sahibi &ldquo;<span class="s1">Caferağa Mah. Şair Nefi Sok. No:46/5 Kadık&ouml;y / İSTANBUL&rdquo;</span> adresinde yerleşik olan Lokma Studio Teknoloji Anonim Şirketi (&ldquo;Lokma Studio&rdquo;) adlı şirkettir. İnternet Sitesi ve Uygulama &uuml;zerinden sunulan Hizmetler, Lokma Studio tarafından sağlanmaktadır.<span class="Apple-converted-space">&nbsp;</span></p>
<p class="p2"><strong>1.3.&nbsp;</strong>Lokma Studio, İnternet Sitesi ve Uygulama&rsquo;da yer alan veya alacak olan bilgileri, formları, her neviden i&ccedil;eriği dilediği zaman, ayrıca herhangi bir bildirimde bulunmaksızın değiştirme hakkını saklı tutar. Lokma Studio, işbu Kullanım Koşulları&rsquo;nda belirtilen koşulları her zaman ve herhangi bir ihbarda veya bildirimde bulunmadan değiştirebilir. Bu değişiklikler, Uygulama&rsquo;da yayımlandığı tarihten itibaren Uygulama&rsquo;yı ziyaret eden veya kullanan herkes i&ccedil;in ge&ccedil;erli olacaktır. İşbu Kullanım Koşulları Ziyaret&ccedil;i ve &Uuml;yeler&rsquo;in tek taraflı beyanı ile değiştirilemez.</p>
<p class="p2"><strong>2.&nbsp;TANIMLAR</strong></p>
<p class="p3"><span class="s1"><strong>İNTERNET SİTESİ:</strong></span> <span class="s1">T&uuml;m hakları Lokma Studio&rsquo;ya ait olan</span> https://lokmastudio.com/ alan adlı internet sitesidir.</p>
<p class="p2"><strong>UYGULAMA:</strong>&nbsp;T&uuml;m hakları Lokma Studio&rsquo;ya ait olan, cep telefonu, tablet ve benzeri taşınabilir cihazlarda &ccedil;alışan yazılımdır.</p>
<p class="p2"><strong>&Uuml;YE:&nbsp;</strong>İnternet Sitesi veya Uygulama &uuml;zerinden oluşturduğu hesap ile &Uuml;yelik S&ouml;zleşmesi&rsquo;nde belirtilen koşullar d&acirc;hilinde İnternet Sitesi ve Uygulama&rsquo;ya &uuml;ye olmuş ger&ccedil;ek kişileri ifade eder.</p>
<p class="p2"><strong>İ&Ccedil;ERİK:</strong>&nbsp;İnternet Sitesi ve Uygulama &uuml;zerinden erişimi m&uuml;mk&uuml;n olan, Lokma Studio tarafından y&uuml;klenmiş, her t&uuml;rl&uuml; bilgi, yazı, dosya, resim, video ve benzeri g&ouml;rsel, yazınsal ve işitsel imgelerlerdir.</p>
<p class="p2"><strong>VERİ TABANI:</strong>&nbsp;İ&ccedil;erikler&rsquo;in depolandığı, tasnif edildiği, sorgulanabildiği ve erişilebildiği ve m&uuml;lkiyeti Lokma Studio&rsquo;ya ait olan ve 5846 sayılı Fikir ve Sanat Eserleri Kanunu gereğince korunan veriler b&uuml;t&uuml;n&uuml; ve bu verilerin okunabilmelerini sağlayan sistemdir.</p>
<p class="p2"><strong>3.&nbsp;UYGULAMA KULLANIM KOŞULLARI VE Y&Uuml;K&Uuml;ML&Uuml;L&Uuml;KLER</strong></p>
<p class="p2"><strong>3.1.</strong>&Uuml;yeler, İnternet Sitesi ve Uygulama&rsquo;yı kullanarak &Uuml;yelik S&ouml;zleşmesi&rsquo;ne ahlak kurallarına ve y&uuml;r&uuml;rl&uuml;kteki mevzuata uymayı kabul ederler. İnternet Sitesi ve Uygulama'da su&ccedil; işlenmesi ve/veya hukuka aykırı fiillerde bulunulması durumunda sorumluluk tamamen &Uuml;ye'ye aittir. &Uuml;ye'nin hareketlerinden doğan hukuki sorunlar veya taleplerden Lokma Studio sorumlu tutulamaz.</p>
<p class="p2"><strong>3.2.</strong>&Uuml;yeler&rsquo;in İnternet Sitesi ve Uygulama&rsquo;yı, siyasi ve/veya felsefi propaganda ama&ccedil;lı g&ouml;r&uuml;ş ve bildiri yayınlamak i&ccedil;in kullanması, İnternet Sitesi ve Uygulama&rsquo;da haksız rekabete yol a&ccedil;an ve ilgilinin kişisel ve/veya ticari itibarını zedeleyecek tarzda davranış veya yorumda bulunması ve İnternet Sitesi ve Uygulama&rsquo;da toplumun zararına olabilecek ya da genel ahlaka ve yasalara aykırı herhangi bir ama&ccedil; g&uuml;den etkinlik ve telkinlerde bulunması yasaktır.<span class="Apple-converted-space">&nbsp;</span></p>
<p class="p2"><strong>3.3.</strong>Diğer &uuml;yelerin ve/veya Lokma Studio&rsquo;nun &ouml;zel ya da gizli dosyalarına, bilgilerine ya da benzer nitelikte i&ccedil;eriğe sahip alanlarına izinsiz olarak ulaşmamayı, bunları kullanmamayı; aksi takdirde bu davranışlardan doğabilecek her t&uuml;rl&uuml; hukuki ve cezai sorumluluğun kendinize ait olduğunu kabul ve taahh&uuml;t eder.</p>
<p class="p2"><strong>3.4.</strong>&Uuml;yeler, hakaret ve taciz, pornografi, yasaklanmış olan kumar, oyun, bahis satışı veya kullanımı re&ccedil;eteye bağlı her t&uuml;rl&uuml; ila&ccedil; ile uyuşturucu madde kapsamına giren ve bağımlılık yaratan bilumum maddelerin tanıtımı, satışı, pazarlaması, tavsiyesi mahiyetindeki materyali, her t&uuml;rl&uuml; insan veya hayvan yaralama, &ouml;ld&uuml;rme, par&ccedil;alama, vahşet kısaca insan psikolojisi a&ccedil;ısından ciddi sakıncalar i&ccedil;eren silah, patlayıcı madde d&acirc;hil her t&uuml;rl&uuml; materyal ile hak sahipliğini veya yetki belgesini ispat edemediği materyali İnternet Sitesi ve Uygulama &uuml;zerinde doğrudan veya mesaj yoluyla paylaşamaz.<span class="Apple-converted-space">&nbsp;</span></p>
<p class="p2"><strong>3.5.</strong>&Uuml;yelik şahsidir ve her bir ger&ccedil;ek kişinin yalnızca bir &uuml;yeliği bulunabilir. &Uuml;yeler, &uuml;yelik ile ilgili olarak &uuml;yelik b&ouml;l&uuml;mde verdiği bilgilerin doğru ve ger&ccedil;ek olduğunu garanti eder.</p>
<p class="p2"><strong>3.6.</strong>&Uuml;yeliğin, &Uuml;ye tarafından, s&uuml;rekli ve/veya ge&ccedil;ici olarak bir başkasına devredilmesi veya kullandırılması m&uuml;mk&uuml;n değildir.</p>
<p class="p2"><strong>3.7.</strong>&Uuml;yeler&rsquo;in sayılan y&uuml;k&uuml;ml&uuml;l&uuml;klere aykırı davranması ve/veya mevzuata g&ouml;re su&ccedil; tanımına girebilecek şekilde paylaşımlarda bulunması yasaktır ve bu t&uuml;rden bir davranışın tespiti halinde ilgili &Uuml;ye, eyleminin t&uuml;m yasal sorumluluğu kendisine ait olmak &uuml;zere &uuml;yelikten &ccedil;ıkartılabilir.</p>
<p class="p2"><strong>3.8.</strong>Lokma Studio, &Uuml;yeler arasındaki iletişimi y&ouml;nlendirmekle ya da bu iletişime m&uuml;dahale etmekle y&uuml;k&uuml;ml&uuml; değildir. Hukukun ve &Uuml;yelik S&ouml;zleşmesi'nin ilgi alanına girmeyen konularda ve hizmetin &ouml;z&uuml; icabı, Lokma Studio&lsquo;nun &Uuml;yeler'e karışma, sınır koyma niyeti, y&uuml;k&uuml;ml&uuml;l&uuml;ğ&uuml; ve sorumluluğu yoktur. &Uuml;yeler, Lokma Studio tarafından sunulan hizmetlerin kullanımı sırasında &uuml;&ccedil;&uuml;nc&uuml; kişilere zarar vermesi halinde, s&ouml;z konusu zarardan bizzat sorumlu olduğunu ve Lokma Studio&rsquo;nun tamamen sorumsuz olduğunu kabul, beyan ve taahh&uuml;t ederler. Bu kapsamda &Uuml;yeler, &uuml;&ccedil;&uuml;nc&uuml; kişilerle doğabilecek uyuşmazlıklarda ve ihtilaflarda; &uuml;&ccedil;&uuml;nc&uuml; kişilerin tazminat taleplerinde Lokma Studio&rsquo;nun sorumlu tutulamayacağını, her ne sebeple olursa olsun tutulması halinde ise Lokma Studio&rsquo;nun katlanmak zorunda kalacağı her t&uuml;rl&uuml; zarar ve masrafı karşılayacağını peşinen kabul ve taahh&uuml;t eder.</p>
<p class="p2"><strong>3.9.</strong>&Uuml;yeler, İnternet Sitesi ve Uygulama'yı herhangi bir &uuml;r&uuml;n&uuml;n ve/veya hizmetin satışına tanıtımına y&ouml;nelik etkinlik de dahil olmak ve fakat bununla kısıtlı olmaksızın herhangi bir ticari ama&ccedil; ve faaliyet i&ccedil;in kullanamaz, Hizmet &uuml;zerinden diğer &Uuml;yeler'den doğrudan veya dolaylı olarak ticari kazan&ccedil; sağlayıcı faaliyetlerde bulunamaz, diğer &Uuml;yeler'den ticari veya başka ama&ccedil;lı olarak e-posta ve telefon numarası da dahil olmak &uuml;zere, herhangi kişisel bilgi toplayamaz.</p>
<p class="p2"><strong>3.10.</strong> Lokma Studio ile paylaşılan kişisel veriler, 6698 sayılı Kişisel Verilerin Korunması Kanunu (&ldquo;KVKK&rdquo;) kapsamında işlenmektedir. Kişisel verilerin işlenmesine ilişkin daha fazla bilgi edinmek i&ccedil;in l&uuml;tfen Kişisel Verilerin İşlenmesine İlişkin Aydınlatma Metni&rsquo;ni inceleyiniz.</p>
<p class="p2"><strong>3.11.&nbsp;</strong>Lokma Studio &uuml;zerinden erişilen ve/veya g&ouml;r&uuml;nt&uuml;lenen her t&uuml;rl&uuml; İ&ccedil;erik&rsquo;in Lokma Studio&rsquo;nun &ouml;nc&uuml;l ve yazılı onayı olmadan &uuml;&ccedil;&uuml;nc&uuml; kişilerce Lokma Studio dışında başta internet olmak &uuml;zere herhangi bir ortamda kullanılamaz ve g&ouml;r&uuml;nt&uuml;lenemez; aksi haller hukuka aykırı kullanım teşkil edecektir.</p>
<p class="p2"><strong>3.12.&nbsp;</strong>Lokma Studio &uuml;zerinden erişilen ve/veya g&ouml;r&uuml;nt&uuml;lenen İ&ccedil;erik&rsquo;in depolandığı Veri Tabanı&rsquo;na &uuml;&ccedil;&uuml;nc&uuml; kişilerce yalnızca ilgili i&ccedil;eriklerin g&ouml;r&uuml;nt&uuml;lenmesi amacıyla ve/veya Lokma Studio&rsquo;nun yazılı olarak izin verdiği şekilde ve kapsamda erişilmesi hukuka uygun olarak kabul edilir. Bunun dışında yapılan erişimler hukuka aykırı olup Lokma Studio&rsquo;nun dava ve takip hakları her zaman i&ccedil;in saklıdır.</p>
<p class="p2"><strong>3.13.&nbsp;</strong>Veri Tabanı ve Veri Tabanı&rsquo;nda yer alan bilgilerin tamamı ya da bir kısmı Lokma Studio&rsquo;nun &ouml;nc&uuml;l ve yazılı onayı olmadan kopyalanamaz, başka veri tabanlarına aktarılamaz ve bu veri tabanlarından &uuml;&ccedil;&uuml;nc&uuml; kişilerin erişimine ve kullanımına a&ccedil;ılamaz.</p>
<p class="p2"><strong>3.14.&nbsp;</strong>Lokma Studio &uuml;zerinden erişilen ve/veya g&ouml;r&uuml;nt&uuml;lenen İ&ccedil;erik&rsquo;i elde etmek maksadıyla Veri Tabanı&rsquo;ndan yoğun şekilde bilgi &ccedil;ekmek yasaktır. Bu yasağa uymayarak Veri Tabanı&rsquo;ndan yoğun şekilde bilgi &ccedil;eken kişilerin, T&uuml;rk Ceza Kanunu&rsquo;nun 243/1. maddesi kapsamında &lsquo;<em>bilişim sistemine yetkisiz giriş yapma</em>&rsquo; eylemini ger&ccedil;ekleştirmeleri sebebiyle y&uuml;r&uuml;rl&uuml;kteki mevzuat uyarınca cezalandırılmaları amacıyla Lokma Studio tarafından bu kişiler hakkında su&ccedil; duyurusunda bulunulacaktır.</p>
<p class="p2"><strong>3.15.&nbsp;</strong>İ&ccedil;erik&rsquo;in Lokma Studio&rsquo;nun yazılı rızası olmadan kopyalanması, &ccedil;oğaltılması, iletilmesi, işlenmesi ve başka internet sitelerinde ve/veya mecralarda g&ouml;r&uuml;nt&uuml;lenmesi yasaktır.</p>
<p class="p2"><strong>3.16.&nbsp;</strong>İnternet Sitesi ve Uygulama &uuml;zerinden erişilen ve/veya g&ouml;r&uuml;nt&uuml;lenen sayfalarda yer alan kişisel veriler, ilgili kişilerin ve Lokma Studio&rsquo;nun yazılı izni olmaksızın hi&ccedil;bir koşul ve şart altında &uuml;&ccedil;&uuml;nc&uuml; kişilerce kullanılmaz, işlenemez, iletilemez, g&ouml;r&uuml;nt&uuml;lenemez ve ifşa edilemez. Ancak Lokma Studio, yasalar &ouml;ng&ouml;rd&uuml;ğ&uuml; takdirde, yasal merci ve makamların kararları doğrultusunda y&uuml;r&uuml;rl&uuml;kteki yasalar kapsamında kalmak şartıyla ve ilgili kararın &ccedil;er&ccedil;evesinde olmak &uuml;zere &Uuml;yeler ile ilgili birtakım bilgileri ilgili makam ve mercilere verebilir.</p>
<p class="p2"><strong>3.17.&nbsp;</strong>&Uuml;yelik S&ouml;zleşmesi, Kullanım Koşulları ve eklerine aykırılık hallerinde Lokma Studio&rsquo;nun maddi ve manevi zararının tazminini talep hakkı saklıdır. &Uuml;yelik S&ouml;zleşmesi, Kullanım Koşulları ve ekleri Lokma Studio&rsquo;nun herhangi bir sebeple yayınının durması/durdurulması halinde bu s&uuml;re boyunca askıda sayılır.<span class="Apple-converted-space">&nbsp;</span></p>
<p class="p2"><strong>3.18.&nbsp;</strong>&Uuml;yeler, işbu Kullanım Koşulları, &Uuml;yelik S&ouml;zleşmesi ve eklerinde sayılan kural ve y&uuml;k&uuml;ml&uuml;l&uuml;klere aykırı davranmaları halinde Lokma Studio&rsquo;nun &uuml;yeliği sona erdirmek ve &Uuml;yeler&rsquo;in Uygulama&rsquo;ya girişini engellemek de dahil olmak ve bununla sınırlı olmamak &uuml;zere her t&uuml;rl&uuml; hakkını saklı tuttuğunu peşinen kabul, beyan ve taahh&uuml;t ederler.</p>
<p class="p2"><strong>4.&nbsp;FİKRİ M&Uuml;LKİYET HAKLARI</strong></p>
<p class="p2"><strong>4.1.&nbsp;</strong>Lokma Studio&rsquo;da erişilen İ&ccedil;erikler ile Lokma Studio&rsquo;nun, Veri Tabanı&rsquo;nın, ara y&uuml;z&uuml;n, tasarımı, metni, kodları da dahil olmak ve bunlarla sınırlı olmamak &uuml;zere t&uuml;m elemanları Lokma Studio&rsquo;ya aittir ve/veya Lokma Studio tarafından &uuml;&ccedil;&uuml;nc&uuml; bir kişiden hukuken ge&ccedil;erli bir lisans altında edinilmiştir. İşbu Kullanım Koşulları d&acirc;hilinde Lokma Studio tarafından a&ccedil;ık&ccedil;a yetki verilmediği hallerde Lokma Studio&rsquo;nun, Veri Tabanı, aray &uuml;z de dahil olmak ve fakat bunlarla sınırlı olmamak &uuml;zere Uygulama&rsquo;dan doğan her t&uuml;rl&uuml; hakkı saklıdır.</p>
<p class="p2"><strong>4.2.&nbsp;</strong>Lokma Studio&rsquo;da kullanılan her t&uuml;rl&uuml; resim, grafik, fotoğraf ve benzeri t&uuml;rden İ&ccedil;erik de Lokma Studio&rsquo;ya aittir ya da yasal sahiplerinden izin alınarak kullanılmaktadır. Lokma Studio tarafından sağlanan bu t&uuml;rden İ&ccedil;erikler&rsquo;in &Uuml;yeler ve &uuml;&ccedil;&uuml;nc&uuml; kişiler tarafından başka ama&ccedil;larla kullanılması Lokma Studio&lsquo;nun &ouml;nc&uuml;l ve yazılı onayına bağlıdır ve bu &ouml;nc&uuml;l ve yazılı onayın bulunmaması halinde kesinlikle yasaktır. Bu t&uuml;rden i&ccedil;eriğin izinsiz kullanımı, telif hakları ihlallerine, ticari markalarla ilgili h&uuml;k&uuml;mlerin ya da y&uuml;r&uuml;rl&uuml;kteki mevzuatın ihlaline yol a&ccedil;abilir. İ&ccedil;erik&rsquo;in izinsiz kullanılması halinde Lokma Studio, bu İ&ccedil;erik&rsquo;ten doğan her t&uuml;rl&uuml; hakkını kullanma yetkisini haizdir.</p>
<p class="p2"><strong>4.3.&nbsp;</strong>Lokma Studio, hukuka, yasalara, telif haklarına ve kişilik haklarına saygılı olmayı ama&ccedil; edinmiştir. Bu nedenle de ge&ccedil;erli telif hakkı sahiplerinin belirtilmesi amacıyla, Lokma Studio i&ccedil;erisinde yer verilen her t&uuml;rden İ&ccedil;erik&rsquo;e ilişkin olmak &uuml;zere, İ&ccedil;erik&rsquo;in elde edildiği kaynak adı ve site adresi ile i&ccedil;eriğin hangi lisans altında elde edildiğine ilişkin uygun a&ccedil;ıklamaya gereken sayfalarda yer verilmiştir. &Uuml;yeler, Hizmetler&rsquo;i, Lokma Studio&rsquo;ya ait olan bilgileri ve Lokma Studio&rsquo;nun telif haklarına tabi &ccedil;alışmalarını yeniden satmak, işlemek, paylaşmak, dağıtmak, sergilemek veya &uuml;&ccedil;&uuml;nc&uuml; kişilerin Lokma Studio&rsquo;nun hizmetlerine erişmesi veya kullanmasına izin vermek hakkına sahip değildirler.</p>
<p class="p2"><strong>5.&nbsp;M&Uuml;CBİR SEBEPLER</strong></p>
<p class="p4">Hukuken m&uuml;cbir sebep sayılan t&uuml;m durumlarda, Lokma Studio Kullanım Koşulları&rsquo;ndan ve/veya &Uuml;yelik S&ouml;zleşmesi&rsquo;nden ve bu metinlerin Uygulama&rsquo;da yayınlanan eklerinden doğan y&uuml;k&uuml;ml&uuml;l&uuml;klerinden herhangi birisini ge&ccedil; ifa etme ve/veya ifa etmeme nedeniyle hukuken sorumlu değildir. Bu ve bunun gibi durumlar, Lokma Studio a&ccedil;ısından, gecikme, ifa etmeme veya temerr&uuml;t addedilmeyecek veya bu durumlar i&ccedil;in Lokma Studio&rsquo;nun &Uuml;yelerin herhangi bir zararını tazmin y&uuml;k&uuml;ml&uuml;l&uuml;ğ&uuml; doğmayacaktır.</p>
<p class="p4">M&uuml;cbir sebep terimi, ilgili tarafın makul kontrol&uuml; haricinde ve Lokma Studio&rsquo;nun gerekli &ouml;zeni g&ouml;stermesine rağmen &ouml;nleyemediği olaylar olarak yorumlanacaktır. Bunun yanında, bunlarla sınırlı olmamakla birlikte, doğal afet, isyan, savaş, grev, iletişim sorunları, altyapı ve internet arızaları ve/veya kesintileri, elektrik kesintisi ve k&ouml;t&uuml; hava koşulları gibi durumlar da m&uuml;cbir sebep kapsamında değerlendirilir.</p>
<p class="p2"><strong>6.&nbsp;UYGULANACAK HUKUK</strong></p>
<p class="p4">İşbu Kullanım Koşulları&rsquo;nın uygulanmasında, yorumlanmasında ve bu Kullanım Koşulları d&acirc;hilinde doğan hukuki ilişkilerin y&ouml;netiminde T&uuml;rkiye Cumhuriyeti hukuku uygulanacaktır. İşbu Kullanım Koşulları&rsquo;ndan dolayı doğan veya doğabilecek her t&uuml;rl&uuml; ihtilafın hallinde ise İstanbul Anadolu Mahkemeleri ve İcra Daireleri yetkilidir.</p>
<p class="p2"><strong>7.&nbsp;Y&Uuml;R&Uuml;RL&Uuml;K</strong></p>
<p class="p4">İşbu Kullanım Koşulları, Lokma Studio tarafından İnternet Sitesi ve Uygulama &uuml;zerinden ilan edildiği tarihte y&uuml;r&uuml;rl&uuml;k kazanır. &Uuml;yeler, işbu Kullanım Koşulları&rsquo;nı Lokma Studio&rsquo;yu ziyaret etmekle ve/veya kullanmakla kabul etmiş olurlar. Lokma Studio, dilediği zaman Kullanım Koşulları h&uuml;k&uuml;mlerinde değişikliğe gidebilir ve değişiklikler Lokma Studio &uuml;zerinde yayınlandığı tarihte y&uuml;r&uuml;rl&uuml;ğe girer.</p>
    </div>
</div>
  )
}

export default KullanimKosullari