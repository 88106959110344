import React from 'react'
import { useEffect } from 'react';
import {Link} from 'react-router-dom';
import LokmaStudioLogo from '../assets/logo-s.svg';
function CerezPolitikasi() {
    useEffect( () => window.scrollTo(0, 0));
  return (
    <div className='MainWrapper'> 
    <Link to="/">  <img src={LokmaStudioLogo} alt="Lokma Logo" /></Link>
    <div className='wrapper'>
    <p class="p1 header"><strong>&Ccedil;EREZ POLİTİKASI</strong></p>
<p class="p2">Bu &Ccedil;erez Politikası, https://lokmastudio.com/ alan adlı internet sitesi (&ldquo;İnternet Sitesi&rdquo;) ve mobil uygulamada (&ldquo;Uygulama&rdquo;) hangi t&uuml;r &ccedil;erezlerin, hangi ama&ccedil;larla kullandığına ilişkin olarak siz ziyaret&ccedil;ileri bilgilendirmek ve &ccedil;erezlere ilişkin tercihlerinizi nasıl y&ouml;netebileceğinizi a&ccedil;ıklamak amacıyla hazırlanmıştır.<span class="Apple-converted-space">&nbsp;</span></p>
<p class="p2">&Ccedil;erezler, ziyaret ettiğiniz internet siteleri tarafından tarayıcılar aracılığıyla cihazınıza veya ağ sunucusuna depolanan k&uuml;&ccedil;&uuml;k metin dosyalarıdır. &Ccedil;erezler, ziyaret ettiğiniz internet sitesiyle ilişkili sunucular tarafından oluşturulurlar ve aşağıdaki ama&ccedil;larla kullanılmaktadır:<span class="Apple-converted-space">&nbsp;</span></p>
<p class="p2">● Ziyaret&ccedil;ilerin davranışlarını analiz etmek ve İnternet Sitesi ve Uygulama&rsquo;nın performansını arttırmak.</p>
<p class="p2">● İnternet Sitesi ve Uygulama&rsquo;nın işlevselliğini arttırmak ve kullanım kolaylığı sağlamak.</p>
<p class="p2">● Kişiselleştirme, hedefleme ve reklamcılık faaliyeti ger&ccedil;ekleştirmek.</p>
<p class="p2">&Ccedil;erezler bu ama&ccedil;ları yerine getirmek i&ccedil;in sizinle ilgili bazı bilgileri hatırlamakta ve bu ama&ccedil;ları yerine getirmemizi sağlamaktadır.</p>
<p class="p2">İnternet Sitesi&rsquo;nde;<span class="Apple-converted-space">&nbsp;</span></p>
<p class="p2">- İnternet Sitesi ve Uygulama&rsquo;ya erişimin sağlanması ve İnternet Sitesi ve Uygulama&rsquo;nın tercihlerinize uygun şekilde &ccedil;alışmasının sağlanabilmesi i&ccedil;in oturum, kullanıcı kimliği ve g&uuml;venlik tipinde temel &ccedil;erezler,</p>
<p class="p2">- &Ccedil;evrimi&ccedil;i davranış ve tercihleriniz konusunda bilgi edinmemiz i&ccedil;in dil, konum, mobil, son ziyaret ve sayfa ge&ccedil;mişi tipinde tercih &ccedil;erezleri,</p>
<p class="p2">- İnternet Sitesi ve Uygulama&rsquo;yı kullanımınız hakkında bilgi toplamak amacıyla Google Analytics tipinde analiz &ccedil;erezleri,</p>
<p class="p2">- İnternet Sitesi ve Uygulama &uuml;zerinden y&uuml;r&uuml;t&uuml;lecek kampanyalar kapsamında pazarlama &ccedil;erezleri</p>
<p class="p2">bulunmaktadır.</p>
<p class="p2">Tarayıcı ayarlarını değiştirerek &ccedil;erezlere ilişkin tercihlerini kişiselleştirme imkanınız bulunmaktadır. Eğer kullandığınız tarayıcı bu imk&acirc;nı sunmaktaysa, tarayıcı ayarları &uuml;zerinden &ccedil;erezlere ilişkin tercihleri değiştirebilirsiniz. B&ouml;ylelikle, tarayıcının sunmuş olduğu imkanlara g&ouml;re farklılık g&ouml;sterebilmekle birlikte, ziyaret&ccedil;ilerin &ccedil;erezlerin kullanılmasını engelleme, &ccedil;erez kullanılmadan &ouml;nce uyarı almayı tercih etme veya sadece bazı &ccedil;erezleri devre dışı bırakma ya da silme imkanları bulunmaktadır.</p>
<p class="p2"><strong>Pop-up metni:</strong> Size daha iyi hizmet verebilmek adına internet sitemizde ve mobil uygulamamızda zorunlu &ccedil;erezler kullanılmaktadır. &ldquo;Kabul Et&rdquo; butonu ile t&uuml;m &ccedil;erezleri kabul edebilir veya &ldquo;&Ccedil;erez Tercihleri&rdquo; se&ccedil;eneği ile bu ayarları kendiniz d&uuml;zenleyebilirsiniz. &Ccedil;erezler hakkında detaylı bilgi edinmek i&ccedil;in l&uuml;tfen tıklayınız.</p>
        
 
    </div>
</div>
  )
}

export default CerezPolitikasi