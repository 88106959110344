import './App.css';

import { Routes, Route, Link } from "react-router-dom";
import Home from './views/Home'
import Kvkk from './views/Kvkk'
import UyelikSozlesmesi from './views/Uyelik-Sozlesmesi';
import KullanimKosullari from './views/Kullanim-Kosullari';
import CerezPolitikasi from './views/Cerez-Politikasi';

function App() {

  return (
    <div className="App">
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route path="/lokma/kvkk" element={<Kvkk />} />
        <Route path="/lokma/uyelik-sozlesmesi" element={<UyelikSozlesmesi />} />
        <Route path="/lokma/kullanim-kosullari" element={<KullanimKosullari />} />
        <Route path="/lokma/cerez-politikasi" element={<CerezPolitikasi />} />
      </Routes>
      <footer><span>© 2022 - LOKMA STUDIO</span> 
        <Link to="/lokma/kvkk"><span>KVKK</span></Link>
        <Link to="/lokma/kullanim-kosullari"><span>Kullanım Koşulları</span></Link>
        <Link to="/lokma/uyelik-sozlesmesi"><span>Üyelik Sözleşmesi</span></Link>
        <Link to="/lokma/cerez-politikasi"><span>Çerez Politikası</span></Link>
         </footer>
    </div>
  );
}


export default App;
