import '../App.css';
import { library } from '@fortawesome/fontawesome-svg-core'
import LokmaLogo from '../assets/logo.svg';
import LokmaStudioLogo from '../assets/logo-s.svg';
import Text from '../assets/text.svg';
import HeroImg from '../assets/hero-img.png';
import { useEffect } from 'react';

function Home() {
    useEffect( () => window.scrollTo(0, 0));
    return (
      <div className="App">
        <header className="App-header">
          <img src={LokmaLogo} alt="Lokma Logo" width={250} />
          <h1>
            YAKINDA
          </h1>
          <h3>Mobil Okurlar İçin Lokmalık Çizgi Roman!</h3>
        </header>
        <div className='Content'>
          <div className='container'>
            <img className='text-img' src={Text} alt="Text" />
            <img className='hero-img' src={HeroImg} alt="Hero" />
          </div>
        </div>
        <div className='Bottom'>
          <div className='bottom-left'>   <img src={LokmaStudioLogo} alt="Lokma Logo" />
          </div>
          <div className='bottom-right'>
            <span>
              LOKMA STUDIO Teknoloji  A.Ş.</span><br />
  
            Caferağa Mah. Şair Nefi Sk. No:46/5<br />
            Kadıköy, İstanbul<br />
            <a href='mailto:info@lokmastudio.com'>info@lokmastudio.com</a>
          </div>
        </div>
        
  
      </div>
    );
  }
  export default Home;