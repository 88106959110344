import React from 'react'
import { useEffect } from 'react';
import LokmaStudioLogo from '../assets/logo-s.svg';
import '../assets/document.css'
import {Link} from 'react-router-dom'
function Kvkk() {
    useEffect( () => window.scrollTo(0, 0));
  return (
    <div className='MainWrapper'> 
    <Link to="/">  <img src={LokmaStudioLogo} alt="Lokma Logo" /></Link>
        <div className='wrapper'>
       
        <p class="p1 header"><strong>KİŞİSEL VERİLERİN İŞLENMESİNE İLİŞKİN AYDINLATMA METNİ</strong></p>
<p class="p2">Bu Kişisel Verilerin İşlenmesine İlişkin Aydınlatma Metni&rsquo;nin (&ldquo;Aydınlatma Metni&rdquo;) temel amacı, Lokma Studio Teknoloji Anonim Şirketi tarafından (&ldquo;Şirket&rdquo;) y&uuml;r&uuml;rl&uuml;kteki mevzuat olan 6698 sayılı Kişisel Verilerin Korunması Kanunu&rsquo;nun (&ldquo;KVKK&rdquo;) 10. maddesinden ve Aydınlatma Y&uuml;k&uuml;ml&uuml;l&uuml;ğ&uuml;n&uuml;n Yerine Getirilmesinde Uyulacak Usul ve Esaslar Hakkında Tebliğ&rsquo;den (&ldquo;Tebliğ&rdquo;) doğan aydınlatma y&uuml;k&uuml;ml&uuml;l&uuml;ğ&uuml;n&uuml; yerine getirmek amacıyla aşağıdaki a&ccedil;ıklamaları ilgili kişilerin dikkatine sunmaktır. Bu &ccedil;er&ccedil;evede, bu Aydınlatma Metni yalnızca Şirket&rsquo;e ait mobil uygulamayı (&ldquo;Uygulama&rdquo;) kullanan ilgili kişilerin mevzuat uyarınca bilgilendirilmesi i&ccedil;in hazırlanmış olup &ccedil;alışan, &ccedil;alışan adayı, tedarik&ccedil;i ve benzeri kişi gruplarının kişisel verilerinin işlenmesine ilişkin durumları kapsamamaktadır.<span class="Apple-converted-space">&nbsp;</span></p>
<p class="p2">T&uuml;m hakları ve kullanıcılar tarafından i&ccedil;erik eklenebilen kısımlar hari&ccedil; olmak &uuml;zere, i&ccedil;eriğinin tamamı Şirket&rsquo;e ait olan https://lokmastudio.com/ alan adlı internet sitesi ve Uygulama&rsquo;nın t&uuml;m yazılımı, tasarımı ve i&ccedil;eriğinde yer alan her t&uuml;rl&uuml; resim, fotoğraf, yazı ve benzeri i&ccedil;eriklere ilişkin t&uuml;m haklar Şirket&rsquo;e aittir ve y&uuml;r&uuml;rl&uuml;kteki mevzuat &ccedil;er&ccedil;evesinde korunmaktadır.</p>
<p class="p2">Bu Aydınlatma Metni, Şirket&rsquo;in İnternet Sitesi ve Uygulama &uuml;zerinden ve/veya vasıtası ile ilgili kişilerden ve/veya ilgili kişilere ilişkin olarak işlediği kişisel verilere ve bu kişisel verilerin işlenmesine ilişkin olarak bilgiler ve veri sorumlusu konusunda ve ilgili kişinin mevzuat kapsamındaki haklarına ilişkin olarak bilgiler i&ccedil;ermektedir. İnternet Sitesi ve Uygulama&rsquo;yı kullanmadan ve genel olarak Şirket&rsquo;in sağladığı hizmetlerden faydalanmadan &ouml;nce, bu Aydınlatma Metni&rsquo;ni okumanız gerekmektedir.<span class="Apple-converted-space">&nbsp;</span></p>
<p class="p2"><strong>1. Kişisel Verilerinizin İşlenmesine İlişkin İlkeler ve Hukuki Sebepler</strong></p>
<p class="p2">Şirket ile İnternet Sitesi ve Uygulama &uuml;zerinden ve vasıtası ile paylaştığınız kişisel veriler, tabi olduğumuz mevzuata uygun şekilde, faaliyet konumuz ve hizmet ama&ccedil;larımızla bağlantılı ve de &ouml;l&ccedil;&uuml;l&uuml; olarak işlenebilecek, gerekli olması durumunda &uuml;&ccedil;&uuml;nc&uuml; kişilere aktarılabilecek ve mevzuata uygun s&uuml;reler boyunca saklanabilecektir.</p>
<p class="p2">Bu kapsamda, kişisel verileriniz; hukuka ve d&uuml;r&uuml;stl&uuml;k kuralına uygun şekilde, doğru ve g&uuml;ncel olarak, belirli, a&ccedil;ık ve meşru ama&ccedil;lar i&ccedil;in ve işlenme ama&ccedil;ları ile bağlantılı, sınırlı ve &ouml;l&ccedil;&uuml;l&uuml; olarak işlenmektedir.</p>
<p class="p2">Kişisel verileriniz, faaliyetlerimizi s&uuml;rd&uuml;rmek başta olmak &uuml;zere bu Aydınlatma Metni&rsquo;nde belirtilen ama&ccedil;larla ve vasıtalarla, mevzuata ve ayrıca Şirketimizin politikalarına uygun şekilde toplanmakta ve toplanan kişisel verileriniz kişisel veri işleme koşullarına uygun şekilde işlenmektedir.</p>
<p class="p2"><strong>2. Kişisel Verilerinizin Toplanma Y&ouml;ntemleri ve Toplanan Kişisel Veriler</strong></p>
<p class="p2">Kişisel verileriniz, İnternet Sitesi ve Uygulama &uuml;zerinden sunulan hizmetlerden yararlanabilmeniz i&ccedil;in işlenmekte ve saklanmaktadır.<span class="Apple-converted-space">&nbsp;</span></p>
<p class="p2">Toplanan kişisel veriler, Şirketimiz tarafından sunulan &uuml;r&uuml;n ve hizmete bağlı olarak değişkenlik g&ouml;sterebilmekle birlikte; otomatik veya otomatik olmayan y&ouml;ntemlerle, İnternet Sitesi ve Uygulama olmak &uuml;zere &ccedil;eşitli vasıtalarla yazılı ya da elektronik olarak toplanabilmektedir.<span class="Apple-converted-space">&nbsp;</span></p>
<p class="p2">Bu kapsamda; Şirketimizin sunduğu hizmetlerden yararlanmanız amacıyla İnternet Sitesi ve Uygulama &uuml;zerinden; adınız, soyadınız, e-posta adresiniz, cep telefonu numaranız, İnternet Sitesi ve Uygulama&rsquo;yı kullanımınızdan elde ettiğimiz bilgiler, ticari elektronik ileti almanız durumunda bunların okunmasına ilişkin bilgiler ile İnternet Sitesi ve Uygulama&rsquo;ya yazdığınız yorumlarınızda tarafınızca sağlanan diğer bilgiler Şirketimiz tarafından toplanmakta ve işlenmektedir.<span class="Apple-converted-space">&nbsp;</span></p>
<p class="p2"><strong>3. Kişisel Verilerinizin İşlenme Nedenleri ve Ama&ccedil;ları</strong></p>
<p class="p2">Kişisel verileriniz; kanunlarda a&ccedil;ık&ccedil;a &ouml;ng&ouml;r&uuml;lmesi, fiili imk&acirc;nsızlık nedeniyle rızasını a&ccedil;ıklayamayacak durumda bulunan veya rızasına hukuki ge&ccedil;erlilik tanınmayan kişinin kendisinin ya da bir başkasının hayatı veya beden b&uuml;t&uuml;nl&uuml;ğ&uuml;n&uuml;n korunması i&ccedil;in zorunlu olması, bir s&ouml;zleşmenin kurulması veya ifasıyla doğrudan doğruya ilgili olması kaydıyla, s&ouml;zleşmenin taraflarına ait kişisel verilerin işlenmesinin gerekli olması, veri sorumlusunun hukuki y&uuml;k&uuml;ml&uuml;l&uuml;ğ&uuml;n&uuml; yerine getirebilmesi i&ccedil;in zorunlu olması, ilgili kişi tarafından alenileştirilmiş olması, bir hakkın tesisi, kullanılması veya korunması i&ccedil;in veri işlemenin zorunlu olması, ilgili kişinin temel hak ve &ouml;zg&uuml;rl&uuml;klerine zarar vermemek kaydıyla, meşru menfaatlerimiz i&ccedil;in veri işlenmesinin zorunlu olması hallerinde a&ccedil;ık rıza olmaksızın ve diğer hallerde a&ccedil;ık rıza bulunması halinde işlenmektedir.</p>
<p class="p2">Toplanan kişisel verileriniz; Şirketimiz tarafından sunulan hizmetlerden kolayca ve g&uuml;venilir şekilde faydalanabilmeniz i&ccedil;in gerekli &ccedil;alışmaların y&uuml;r&uuml;t&uuml;lmesi; hizmetlerimizin taleplerinizi, ihtiya&ccedil;larınızı ve kullanım alışkanlıklarınızı karşılayacak şekilde &ouml;zelleştirilerek sizlere &ouml;nerilebilmesi; m&uuml;şteri/kullanıcı ilişkilerinin y&uuml;r&uuml;t&uuml;lmesi, satış ve pazarlama faaliyetlerinin s&uuml;rd&uuml;r&uuml;lmesi, Şirketimizin hukuki y&uuml;k&uuml;ml&uuml;l&uuml;klerinin yerine getirilmesi, raporlama s&uuml;re&ccedil;lerinin y&uuml;r&uuml;t&uuml;lmesi ve hukuki s&uuml;re&ccedil;lerin hızlı ve etkili bir şekilde y&uuml;r&uuml;t&uuml;lebilmesinin temini ama&ccedil;larıyla, KVKK&rsquo;da belirtilen koşullara uygun şekilde işlenmektedir. Bu kapsamda kişisel verileriniz; İnternet Sitesi ve Uygulama&rsquo;yı kullanan kişinin bilgilerini tespit ve teyit etmek, sunulan hizmetler kapsamındaki sorun ve hataların tespit etmek, talep, şik&acirc;yet ve &ouml;nerileri değerlendirebilmek ve &ccedil;&ouml;z&uuml;me kavuşturmak ve y&uuml;r&uuml;rl&uuml;kteki mevzuattan kaynaklanan hak ve y&uuml;k&uuml;ml&uuml;l&uuml;klerimizi ifa etmek ama&ccedil;larıyla da işlenmektedir.</p>
<p class="p2">T&uuml;m bunlara ek olarak, kişisel veriler; acil durum y&ouml;netimi s&uuml;re&ccedil;lerinin y&uuml;r&uuml;t&uuml;lmesi, faaliyetlerin mevzuata uygun y&uuml;r&uuml;t&uuml;lmesi, finans ve muhasebe işlerinin y&uuml;r&uuml;t&uuml;lmesi, hukuk işlerinin takibi ve y&uuml;r&uuml;t&uuml;lmesi, iletişim faaliyetlerinin y&uuml;r&uuml;t&uuml;lmesi, iş faaliyetlerinin y&uuml;r&uuml;t&uuml;lmesi ve denetimi, hizmet satın alım s&uuml;re&ccedil;lerinin y&uuml;r&uuml;t&uuml;lmesi, hizmet satış sonrası destek hizmetlerinin y&uuml;r&uuml;t&uuml;lmesi, hizmet satış s&uuml;re&ccedil;lerinin y&uuml;r&uuml;t&uuml;lmesi, hizmet &uuml;retim ve operasyon s&uuml;re&ccedil;lerinin y&uuml;r&uuml;t&uuml;lmesi, m&uuml;şteri/kullanıcı ilişkileri y&ouml;netimi s&uuml;re&ccedil;lerinin y&uuml;r&uuml;t&uuml;lmesi, m&uuml;şteri/kullanıcı memnuniyetine y&ouml;nelik aktivitelerin y&uuml;r&uuml;t&uuml;lmesi, organizasyon ve etkinlik y&ouml;netimi, pazarlama analiz &ccedil;alışmalarının y&uuml;r&uuml;t&uuml;lmesi, performans değerlendirme s&uuml;re&ccedil;lerinin y&uuml;r&uuml;t&uuml;lmesi, reklam ve/veya kampanya ve/veya promosyon s&uuml;re&ccedil;lerinin y&uuml;r&uuml;t&uuml;lmesi, saklama ve arşiv faaliyetlerinin y&uuml;r&uuml;t&uuml;lmesi, s&ouml;zleşme s&uuml;re&ccedil;lerinin y&uuml;r&uuml;t&uuml;lmesi, sponsorluk faaliyetlerinin y&uuml;r&uuml;t&uuml;lmesi, talep ve/veya şikayetlerin takibi, pazarlama s&uuml;re&ccedil;lerinin y&uuml;r&uuml;t&uuml;lmesi, veri sorumlusu operasyonlarının g&uuml;venliğinin temini, yatırım s&uuml;re&ccedil;lerinin y&uuml;r&uuml;t&uuml;lmesi, yetkili kişi, kurum ve kuruluşlara bilgi verilmesi, y&ouml;netim faaliyetlerinin y&uuml;r&uuml;t&uuml;lmesi ama&ccedil;ları doğrultusunda da işlenmektedir.<span class="Apple-converted-space">&nbsp;</span></p>
<p class="p2">&Ouml;zel nitelikli kişisel veriler Şirketimiz tarafından ilgili kişinin a&ccedil;ık rızası bulunmaksızın işlenmemektedir.</p>
<p class="p2"><strong>4. Kişisel Verilerin G&uuml;venliği<span class="Apple-converted-space">&nbsp;</span></strong></p>
<p class="p2">Şirket, y&uuml;r&uuml;rl&uuml;kteki mevzuat uyarınca kişisel verilerin gizliliğinin ve b&uuml;t&uuml;nl&uuml;ğ&uuml;n&uuml;n korunması amacıyla gerekli organizasyonu kurmak ve teknik &ouml;nlemleri almak ve uygulamak konusunda mevzuattan doğan y&uuml;k&uuml;ml&uuml;l&uuml;klerine uygun hareket etmektedir. Kişisel verileriniz, Şirket&rsquo;in faaliyet konusu kapsamında ve Aydınlatma Metni&rsquo;nde belirtilen ama&ccedil;ları doğrultusunda işlenecek, işlenme ama&ccedil;larının gerektirdiği s&uuml;reler ve/veya y&uuml;r&uuml;rl&uuml;kteki mevzuatta &ouml;ng&ouml;r&uuml;len s&uuml;re boyunca saklanabilecek ve işlenmesini gerektiren sebeplerin ortadan kalkması h&acirc;linde, y&uuml;r&uuml;rl&uuml;kteki mevzuattan doğan saklama mecburiyeti bulunan haller saklı olmak &uuml;zere, resen veya ilgili kişinin talebi &uuml;zerine silinecek, yok edilecek veya anonim hale getirilecektir. Kişisel verilerinizin işlenmesi ve muhafazası yalnızca bu konuda yetkilendirilmiş ve eğitilmiş personeller tarafından ve veri g&uuml;venliğinin sağlanabildiği alanlarda yapılmaktadır.</p>
<p class="p2">Kişisel verileriniz, Şirket&rsquo;in sunduğu hizmetler ve faaliyet konusu &ccedil;er&ccedil;evesinde; hukuka ve d&uuml;r&uuml;stl&uuml;k kurallarına uygun, doğru ve g&uuml;ncel şekilde; işbu Aydınlatma Metni&rsquo;nde işlendikleri ama&ccedil;la bağlantılı, sınırlı ve &ouml;l&ccedil;&uuml;l&uuml; olarak ve de ilgili mevzuatta &ouml;ng&ouml;r&uuml;len veya işlendikleri ama&ccedil; i&ccedil;in gerekli olan s&uuml;re kadar muhafaza edilmek sureti ile işlenmektedir.<span class="Apple-converted-space">&nbsp;</span></p>
<p class="p2">Şirket tarafından kişisel verilerin işlenmesine ilişkin olarak aşağıdaki &ouml;nlemler alınmaktadır:<span class="Apple-converted-space">&nbsp;</span></p>
<ul class="ul1">
<li class="li2"><strong>İdari Tedbirler</strong></li>
<li class="li2"><strong>Teknik Tedbirler</strong></li>
</ul>
<p class="p2"><strong>5. Kişisel Verilerinizin Aktarımı</strong></p>
<p class="p2">Şirket tarafından kişisel verileriniz mevzuatta belirtilen kurallar ve bu Aydınlatma Metni&rsquo;nde belirtilen ama&ccedil;lar dahilinde, kural olarak, ilgili kişilerin a&ccedil;ık rızası bulunmaksızın &uuml;&ccedil;&uuml;nc&uuml; kişilere aktarılmamaktadır. Bununla birlikte, kişisel veri işleme ama&ccedil;ları doğrultusunda gerekli g&uuml;venlik &ouml;nlemlerini de almak sureti ile kişisel veriler yurt i&ccedil;indeki ve yurt dışındaki &uuml;&ccedil;&uuml;nc&uuml; kişilere, iş ortaklarımıza, ifa yardımcılarımıza ve sunduğumuz hizmet ve faaliyet amacımız doğrultusunda ya da ilgili mevzuatın &ouml;ng&ouml;rd&uuml;ğ&uuml; durumlarda d&uuml;zenleyici denetleyici kurumlara ve de resmi mercilere aktarılabilmektedir. Kişisel verilerin &uuml;&ccedil;&uuml;nc&uuml; kişiler ile paylaşımı, m&uuml;şterilerin izni &ccedil;er&ccedil;evesinde ger&ccedil;ekleşmekte ve kural olarak ilgili m&uuml;şterimizin a&ccedil;ık rızası olmaksızın kişisel veriler &uuml;&ccedil;&uuml;nc&uuml; kişilere aktarılmamaktadır.</p>
<p class="p2">Kişisel verileriniz, Şirket&rsquo;in hissedarları, iştirakleri ve bağlı ortaklıkları, topluluk şirketleri ve yetkili kamu kurum ve kuruluşlarına aktarılmakta ve bunlara ek olarak, Şirket &ccedil;alışanlarına, tedarik&ccedil;ilerle, iş ortaklarıyla, danışmanlarla ve &ouml;zel kişilerle mevzuat dahilinde paylaşılabilmektedir.</p>
<p class="p2"><strong>6. Kişisel Verilerinize İlişkin Haklarınız</strong></p>
<p class="p2">Kişisel verilerin işlenmesi bakımından, y&uuml;r&uuml;rl&uuml;kteki mevzuattaki tanım uyarınca veri sorumlusu, Lokma Studio Teknoloji A.Ş.&rsquo;dir.</p>
<p class="p2">KVKK uyarınca kişisel verilerinizin; işlenip işlenmediğini &ouml;ğrenme, işlenmişse bilgi talep etme, işlenme amacını ve amacına uygun kullanılıp kullanılmadığını &ouml;ğrenme, yurt i&ccedil;inde ve yurt dışında aktarıldığı &uuml;&ccedil;&uuml;nc&uuml; kişileri &ouml;ğrenme, eksik veya yanlış işlenmişse d&uuml;zeltilmesini isteme, KVKK&rsquo;nın 7. maddesinde &ouml;ng&ouml;r&uuml;len şartlar &ccedil;er&ccedil;evesinde silinmesini veya yok edilmesini isteme, aktarıldığı &uuml;&ccedil;&uuml;nc&uuml; kişilere yapılan işlemlerin bildirilmesini isteme, m&uuml;nhasıran otomatik sistemler ile analiz edilmesi nedeniyle aleyhinize bir sonucun ortaya &ccedil;ıkmasına itiraz etme ve KVKK&rsquo;ya aykırı olarak işlenmesi sebebiyle zarara uğramanız h&acirc;linde zararın giderilmesini talep etme haklarına sahip olduğunuzu hatırlatmak isteriz.</p>
<p class="p2">Belirtilen haklarınızı kullanmak i&ccedil;in, bu y&ouml;ndeki talebinizi KVKK&rsquo;nın 13. maddesi doğrultusunda, Şirket&rsquo;in aşağıda belirtilen adresine, adınızı ve soyadınızı ve de imzanızı, T&uuml;rkiye Cumhuriyeti vatandaşı iseniz T.C. kimlik numaranızı, T&uuml;rkiye Cumhuriyeti vatandaşı değilseniz uyruğunuzu, pasaport numaranızı veya varsa kimlik numaranızı, tebligata esas yerleşim yeri veya iş yeri adresinizi, bildirime esas elektronik posta adresi ve telefon numaranızı ve de talep konusunu ve kimliğinizi tespite yarayan gerekli bilgi ve belgeleri eklemek sureti ile yazılı olarak veya Uygulama&rsquo;ya kayıtlı e-posta adresiniz &uuml;zerinden başvuruda bulunabilirsiniz.</p>
<p class="p2">Yukarıda sıralanan haklarınıza ilişkin talepleriniz, taleplerinizi yazılı olarak Şirket&rsquo;e iletmeniz durumunda en ge&ccedil; 30 (otuz) g&uuml;n i&ccedil;inde Şirketimiz tarafından &uuml;cretsiz olarak sonu&ccedil;landırmaktadır. Ancak, işlemin ayrıca bir maliyeti gerektirmesi h&acirc;linde, Şirket tarafından Kişisel Verilerin Korunması Kurulu&rsquo;nca belirlenen tarifedeki &uuml;cret alınacaktır.</p>
<p class="p2">Belirtilen bu haklarınızı kullanmak i&ccedil;in, bu y&ouml;ndeki talebinizi KVKK&rsquo;nın 13. maddesi doğrultusunda, Şirket&rsquo;in aşağıda belirtilen adresine, adınızı ve soyadınızı ve de imzanızı, T&uuml;rkiye Cumhuriyeti vatandaşı iseniz T.C. kimlik numaranızı, T&uuml;rkiye Cumhuriyeti vatandaşı değilseniz uyruğunuzu, pasaport numaranızı veya varsa kimlik numaranızı, tebligata esas yerleşim yeri veya iş yeri adresinizi, bildirime esas elektronik posta adresi ve telefon numaranızı ve de talep konusunu ve kimliğinizi tespite yarayan gerekli bilgi ve belgeleri eklemek sureti ile elden teslim edebilir ya da noter kanalıyla yazılı olarak veyahut da Uygulama&rsquo;ya kayıtlı e-posta adresiniz &uuml;zerinden başvuruda bulunabilirsiniz.</p>
<p class="p2">Veri Sorumlusu: Lokma Studio Teknoloji Anonim Şirketi<span class="s1"> <span class="Apple-converted-space">&nbsp;</span></span></p>
<p class="p3"><span class="s1">Adresi: </span>Caferağa Mah. Şair Nefi Sok. No:46/5 Kadık&ouml;y / İSTANBUL</p>
        </div>
    </div>
  )
}

export default Kvkk